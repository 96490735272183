import React from "react";
import DefaultLayout from "../components/DefaultLayout";
import Hero from "../components/bricks/Hero";

import Strip from "../components/ui/Strip";
import {Text} from "../components/ui/Text";
import {PlainButton} from "../components/Button";
import Col from "../components/ui/Col";

const NotFoundPage = ({location}) => (
  <DefaultLayout title="Page Not Found" footer={null} location={location}>
    <Hero size="sm" title="Where are we?" />
    <Strip bg="gray100" py={7} sp={6} fillParent>
      <Text preset="bold" size={5} color="gray800" align="center">
        You just hit a route that doesn&#39;t exist...
      </Text>
      <Col align="center">
        <PlainButton to="/">Back to Homepage</PlainButton>
      </Col>
    </Strip>
  </DefaultLayout>
);

export default NotFoundPage;
