import React from "react";
import css from "@emotion/css";
import Col from "./Col";

const styles = {
  inner: {
    default: {width: "100%"},
    width: {
      xs: css({maxWidth: "35rem"}),
      sm: css({maxWidth: "45rem"}),
      md: css({maxWidth: "60rem"}),
      lg: css({maxWidth: "80rem"}),
    },
  },
};

const Strip = React.forwardRef(({size = "md", sp, children, fillParent, ...rest}, ref) => (
  <Col px={4} align="center" fillParent={fillParent} {...rest} ref={ref}>
    <Col sp={sp} fillParent={fillParent} css={[styles.inner.default, styles.inner.width[size]]}>
      {children}
    </Col>
  </Col>
));

export default Strip;
